.defaultToggle {
    display: flex;
    align-items: center;
    font-family: bold;
    margin-bottom: 40px;

    & > label {
        margin-right: 10px;
    }
}
