.table {
    margin-top: 20px;
    width: 100%;
}

.actionsCell {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
