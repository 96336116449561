.bannersTable {
    width: 100%;

    tr:nth-child(2n) {
        background: var(--color-accordion-section-title-resting);
        border: 1px solid var(--color-secondary-dark);
        border-right: none;
        border-left: none;
    }

    th,
    td {
        text-align: left;

        &.alignRight {
            text-align: right;
        }

        & > label {
            margin-right: 0;
        }
        &.disabled {
            cursor: no-drop;
        }
    }

    .name {
        display: flex;
        align-items: center;
        padding-left: 10px;

        & > img {
            width: 32px;
            height: 32px;
            margin-right: 5px;
        }
    }

    .link {
        color: var(--color-primary);
    }

    .buttons > button {
        margin: 0 3px;
    }
}
