.modal {
    padding: 0;
}

.header {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.searchBar {
    margin-left: auto;
}

.checkbox {
    text-align: right;

    padding-right: 10px;
}

.footer {
    background-color: var(--color-grey-background);
    padding: 20px;
}

.form {
    display: flex;
    gap: 10px;
    align-items: center;
}

.dateInput {
    background-color: var(--color-white);
}

.addButton {
    margin-left: auto;
}

.table {
    width: 100%;
}
