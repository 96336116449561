.group {
    display: inline-flex;
    gap: 4px;
    background-color: var(--color-grey-background);
    border-radius: 4px;
    padding: 4px;

    & button {
        transition: background-color 0.1s ease, color 0.1s ease;
    }
    & a {
        text-decoration: none;
    }
}

.button {
    background-color: var(--color-grey-background);
    color: var(--color-grey-text);

    &:hover:not(.active) {
        background-color: color-mix(
            in srgb,
            var(--button-background) 20%,
            transparent
        );
    }
}

.active {
    background-color: var(--button-background);
    color: var(--button-color);
}
