@import './styles/variables.scss';
@import './styles/varOverwrite.scss';

@font-face {
    font-family: 'bold';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/SagoMiniRounded-Bold_v2.otf');
}

@font-face {
    font-family: 'standard';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/SagoMiniRounded-Regular_v2.otf');
}

body {
    margin: 0;
    padding: 0;
    font-family: 'bold', sans-serif;
    color: var(--color-body-text);
    background-color: var(--color-body);
    letter-spacing: 0.03em;
    -webkit-font-smoothing: antialiased;
}

p.noMargin {
    margin: 0;
    padding: 0;
}

h1 {
    margin-top: 10px;
    color: var(--color-h1);
    font-size: 1.6em;
    display: inline-block;
}

table {
    border-collapse: collapse;

    thead tr {
        border-bottom: 1px solid #e5e5e5;

        th {
            text-align: left;
            font-family: 'standard';
            font-size: 0.9em;
            letter-spacing: 0.06em;
            font-weight: normal;
            color: #9e9e9e;
        }

        th div.sortable {
            display: flex;
            align-items: center;
            flex-direction: row;
            cursor: pointer;

            label {
                cursor: inherit;
            }
        }
    }

    tbody {
        td {
            padding: 12px 0;

            .status {
                font-size: 0.8em;

                &.new {
                    color: #2677ca;
                }

                &.invalid {
                    color: #ce1414;
                }
            }
        }

        td.validate {
            padding: 0;
        }

        tr:hover {
            cursor: pointer;
            background: var(--color-secondary-light);
        }

        tr.no-highlight:hover {
            cursor: auto;
            background: unset;
        }

        .inactive {
            color: #aaa;
        }

        .disabled {
            color: #aaa;
            pointer-events: none;
        }
    }

    .dragHandle {
        font-family: 'Courier New';
        font-size: 3.2em;
        color: var(--color-default-light);
        line-height: 0.4;
        padding-top: 9px;
        padding-right: 10px;
        text-align: right;
    }

    .dragHandle.alignLeft {
        text-align: left;
    }

    .dragHandle:hover {
        color: var(--color-default);
    }

    .thumb {
        height: 25px;
    }
}

section {
    .part {
        margin-bottom: 35px;

        .pack {
            display: inline-block;
            width: 270px;
            font-family: 'standard';
            font-size: 0.95em;
            text-transform: none;
            margin-bottom: 5px;

            &.row {
                border-bottom: 1px solid #eee;
            }
        }
    }
}

img.arrow.down {
    transform: rotate(0deg);
}

img.arrow.right {
    transform: rotate(-90deg);
}

img.arrow.left {
    transform: rotate(90deg);
}

img.arrow.up {
    transform: rotate(180deg);
}

a {
    color: var(--color-h1);
}

.blur {
    filter: blur(5px) grayscale(100%);
}

button:focus,
li:focus,
*:focus {
    outline: none;
}

button {
    letter-spacing: 0.03em;
    border-radius: 5px;
    padding: 11px 15px 9px 15px;
    cursor: pointer;
    border: none;
    font-size: 1.1em;
    font-family: 'bold';
    --button-color: #ffffff;
    --button-background: var(--color-default);
    background-color: var(--button-background);
    color: var(--button-color, #ffffff);

    &:disabled {
        --button-background: var(--color-default-light);
        cursor: not-allowed;
    }

    &.primary {
        --button-background: var(--color-primary);

        &:disabled {
            --button-background: var(--color-primary-light);
        }
    }

    &.secondary {
        --button-background: var(--color-secondary);
        --button-color: var(--color-black);

        &:disabled {
            --button-background: var(--color-secondary-light);
            --button-color: var(--color-black-light);
        }

        --spinner-color: var(--color-black-light);
    }

    &.positive {
        --button-background: var(--color-positive);

        &:disabled {
            --button-background: var(--color-positive-light);
        }
    }

    &.warning {
        --button-background: var(--color-warning);

        &:disabled {
            --button-background: var(--color-warning-light);
        }
    }

    &.danger {
        --button-background: var(--color-danger);

        &:disabled {
            --button-background: var(--color-danger-light);
        }
    }

    &.new {
        padding: 8px 15px 7px 15px;
        float: 'right';
        margin-left: 15px;
        margin-top: 10px;
        font-size: '.7em';
    }

    &.withHeading {
        float: right;
        margin-left: 15px;
        margin-top: 10px;
        font-size: 0.7em;
    }

    &.inSectionHeading {
        position: absolute;
        right: 10px;
        top: -50px;
        font-size: 0.7em;
    }

    &.unselected {
        background: #fff;
        border: 2px solid #3c3a44;
        --button-color: #3c3a44;
        padding: 6px 15px 5px 15px !important;
    }

    &.small {
        padding: 8px 15px 7px 15px;
        font-size: 0.7em;
    }

    &.medium {
        padding: 10px 15px 8px 15px;
        font-size: 0.9em;
    }

    &.link {
        background: none;
        padding: 0;
        margin: 0;
        text-decoration: underline;
        font-size: 1em;
        --button-color: var(--color-default);

        &.primary {
            --button-color: var(--color-primary);
        }

        &.secondary {
            --button-color: var(--color-secondary);
        }

        &.positive {
            --button-color: var(--color-positive);
        }

        &.warning {
            --button-color: var(--color-warning);
        }

        &.danger {
            --button-color: var(--color-danger);
        }
    }
}

span {
    &.label {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 8px;
        padding-left: 8px;
        border-radius: 4px;
    }

    &.new {
        background-color: #bae184;
    }

    &.update {
        background-color: #e0f0ff;
    }

    &.delete {
        background: #dde9e7;
    }

    &.invalid {
        background: #f7abab;
    }
}

label.upload {
    display: inline-block;
    border-radius: 3px;
    background: #43404c;
    font-family: 'bold';
    border-radius: 5px;
    color: #ffffff;
    font-size: 0.9em;
    padding: 13px 15px 11px 15px;
    cursor: pointer;
    border: none;
    font-size: 1.1em;

    &.disabled {
        background-color: var(--color-default-light);
        cursor: not-allowed;
    }
}

.editInput,
.labelInput {
    margin: 0 20px 20px 0;
    display: inline-block;

    label {
        margin-bottom: 5px;
    }

    label,
    th,
    h3 {
        color: #909090;
        font-size: 0.85em;
        text-align: left;
        font-family: 'standard';
        text-transform: uppercase;
    }

    &.prices {
        margin-bottom: 0;
    }

    &.districts,
    &.playsets {
        margin-bottom: 0px;
        margin-right: 5px;
    }
}

h3 {
    margin-bottom: 5px;
}

h3:not(:first-child) {
    margin-top: 50px;
}

.districts label,
.playsets label {
    text-transform: initial;
}

.scene,
.extraContent {
    display: inline-block;
    background: #ffae53;
    border-radius: 5px;
    padding: 10px 15px 8px 15px;
    margin: 0 15px 10px 0;
    color: white;
    position: relative;
}

.extraContent {
    background: #21d86a;
}

.scene img,
.extraContent img {
    position: absolute;
    height: 21px;
    top: -9px;
    right: -9px;
}

.labelInput label,
.editInput label {
    display: block;
}

input::placeholder {
    color: #bbbbbb;
}

section .platformData label {
    display: inline-block;
}

.alignRight {
    text-align: right;
}

input {
    font-family: 'bold';
    font-size: 1em;
    letter-spacing: 0.03em;
    padding: 13px 10px 10px 15px;
    border: 0px solid #ccc;
    border-radius: 4px;
    background-color: var(--color-grey-background);
}

.semiwide {
    width: 434px;
}

.wide {
    width: 534px;
}

.doublewide {
    width: 1113px;
}

.narrow {
    width: 118px;
}

.tiny {
    width: 67px;
}

.instructions {
    color: gray;
    font-size: 0.9em;
    font-family: standard;
    margin-top: 30px;
    margin-bottom: 50px;
}

img.loading,
span.loadingsavepa {
    animation: rotate 2s infinite linear;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

img.fetching {
    animation-name: fetching;
    animation-duration: 4s;
    position: relative;
}

@keyframes fetching {
    0% {
        left: -50px;
        top: 0px;
    }

    100% {
        left: 50px;
        top: 0px;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.react-draggable strong {
    background: #ddd;
    border: 1px solid #999;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
    padding: 3px 5px;
    text-align: center;
}

.box {
    background: #fff;
    border: 1px solid #999;
    border-radius: 3px;
    width: 180px;
    height: 180px;
    margin: 10px;
    padding: 10px;
    float: left;
}

/* map */

#map {
    position: relative;
    text-align: center;

    #screen {
        margin: auto;
        overflow: hidden;
        white-space: nowrap;
        position: absolute;
        background: #ebf3f2;
    }

    .districtChildPack {
        display: inline-block;
        background: #edefef;
        border-radius: 5px;
        color: black;
        margin: 10px;
        padding: 15px 20px 13px 20px;

        &.active {
            background: var(--color-header);
        }
    }

    section {
        text-align: center;
    }

    .pricetag {
        cursor: pointer;
        z-index: 2;
        position: absolute;
        transform-origin: top left;
        transform-origin: top left;

        &.active {
            outline: 5px dotted white;
            outline-offset: 15px;
        }
    }

    .instructions div {
        margin-bottom: 20px;
        text-align: left;

        span {
            display: inline-block;
            min-width: 150px;
        }
    }

    .district {
        display: inline-block;
        position: relative;
        background-size: 100% 100%;
        overflow: hidden;
        text-align: center;

        &.inactive {
            filter: brightness(50%);
        }
    }

    .sign {
        $pos: 2px;
        color: white;
        padding: 4px 15px 0px 15px;
        display: inline-block;
        text-transform: uppercase;
        user-select: none;
        border-radius: 4px;
        font-size: 3em;
        position: relative;
        font-family: standard;

        div {
            border-radius: 10px;
            width: 3px;
            height: 3px;
            background: white;
            position: absolute;
        }

        :nth-child(1) {
            bottom: $pos;
            left: $pos;
        }

        :nth-child(2) {
            top: $pos;
            left: $pos;
        }

        :nth-child(3) {
            bottom: $pos;
            right: $pos;
        }

        :nth-child(4) {
            top: $pos;
            right: $pos;
        }

        &.style0 {
            $b: darken(nth($colors-mapsign, 1), 20%);
            background: nth($colors-mapsign, 1);
            border: 2px solid $b;
            text-shadow: -1px -1px 0 $b, 1px -1px 0 $b, -1px 1px 0 $b,
                1px 1px 0 $b;

            div {
                border: 1px solid $b;
            }
        }

        &.style1 {
            $b: darken(nth($colors-mapsign, 2), 20%);
            background: nth($colors-mapsign, 2);
            border: 2px solid $b;
            text-shadow: -1px -1px 0 $b, 1px -1px 0 $b, -1px 1px 0 $b,
                1px 1px 0 $b;

            div {
                border: 1px solid $b;
            }
        }

        &.style2 {
            $b: darken(nth($colors-mapsign, 4), 20%);
            background: nth($colors-mapsign, 4);
            border: 2px solid $b;
            text-shadow: -1px -1px 0 $b, 1px -1px 0 $b, -1px 1px 0 $b,
                1px 1px 0 $b;

            div {
                border: 1px solid $b;
            }
        }

        &.style3 {
            $b: darken(nth($colors-mapsign, 3), 20%);
            background: nth($colors-mapsign, 3);
            border: 2px solid $b;
            text-shadow: -1px -1px 0 $b, 1px -1px 0 $b, -1px 1px 0 $b,
                1px 1px 0 $b;

            div {
                border: 1px solid $b;
            }
        }
    }

    #districts {
        button {
            font-size: 0.7em;
            padding: 7px 10px 5px 10px;
            margin-left: 5px;
        }
    }
}

.savePanel {
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 15px 20px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 15px;

    border: 5px solid rgba(0, 0, 0, 0.05);

    div {
        &.items {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

select {
    -webkit-appearance: none;
    -webkit-user-select: none;
    background-color: #7c70c0;
    /* fallback color if gradients are not supported */
    background-image: url('./assets/dropdown.png');
    background-position: center right 10px;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 1em;
    margin: 15px;
    padding: 15px 30px 11px 15px;
    border: none;
    font-family: 'bold';
    cursor: pointer;
}

.dropdown {
    margin: 0;
    letter-spacing: 0.03em;
    padding: 13px 10px 10px 15px;
    color: #000;
    border: 0px solid #ccc;
    border-radius: 4px;
    background-color: var(--color-grey-background);
}

div.inactiveDisclaimer {
    color: #909090;
    font-size: 0.8em;
    text-align: left;
    font-family: 'standard';
    text-decoration: none;
}

tr.draggablePack {
    &.inactive {
        & img {
            filter: grayscale(1);
        }
    }
}

div.photo,
div.pack {
    max-width: 290px;
    margin-right: 3%;
    display: inline-block;
    position: relative;

    &.inactive {
        & a:link .inactiveDisclaimer {
            text-decoration: none;
        }

        & img {
            filter: grayscale(1);
        }
    }
}

div.video {
    max-width: 450px;
    margin-right: 3%;
    display: inline-block;
    position: relative;
}

.uploading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    box-sizing: border-box;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.75);
}

div.photo img,
div.pack img {
    width: 100%;
}

.noMediaWrapper {
    display: inline-flex;
    flex-direction: row;
    border: solid 3px var(--color-grey-background);
    border-radius: 6px;
    align-items: center;
    padding: 10px;

    & > *:not(:first-child) {
        margin-left: 10px;
    }
}

.deleteIcon {
    background-color: transparent;
    background-image: url('./assets/delete.png');
    background-repeat: no-repeat;
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 0;
    position: absolute;
    top: -15px;
    right: -15px;
}

.deletable {
    max-width: 500px;
    position: relative;
    display: inline-block;
}

.deletable .deleteIcon {
    display: none;
}

.deletable .deleteIcon:hover {
    background-color: transparent;
}

.deletable:hover .deleteIcon {
    display: block;
}

input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

input[type='radio'] {
    margin-right: 5px;
}

input[type='checkbox'] {
    margin-right: 3px;
}

/* turn off number spinners which looks bad with the designed text fields */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.speech-bubble {
    position: relative;
    background: var(--color-speechbubble);
    border-radius: 0.4em;
    display: inline-block;
    padding: 0px 10px;
    font-family: 'standard';
    font-size: 0.9em;
    padding: 10px 15px;
    margin-left: 13px;
    text-align: left;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right-color: var(--color-speechbubble);
    border-left: 0;
    margin-top: -8px;
    margin-left: -8px;
}

.modalBackdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 50;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background-color: #ffffff;
    border-radius: 5px;
    width: 400px;
    max-height: 500px;
    overflow: auto;
    padding: 30px;
    box-shadow: 10px 10px 39px 0px rgba(0, 0, 0, 0.34);

    .wrapper-diff {
        .wrapper-diff-row {
            margin-bottom: 30px;

            .diff-title {
                margin-bottom: 10px;
                font-size: 0.9em;
                font-family: 'standard', sans-serif;
                color: #9e9e9e;
            }

            .container-diff {
                padding: 10px;
                text-align: center;
                margin-bottom: 10px;
                border-radius: 5px;
                overflow: auto;
                background: rgb(243, 243, 243);
            }

            .container-diff-lhs {
                background: rgb(254, 225, 224);
            }

            .container-diff-rhs {
                background: rgb(221, 254, 223);
            }
        }
    }
}

.ABTestItem {
    display: flex;
    align-items: center;
    height: 65px;
    opacity: 0;
    border-bottom: 1px dashed #eee;
}

.ABTestItem:hover {
    background: #f0f8fb;
}

div.wrap {
    overflow-wrap: anywhere;
}

fieldset {
    display: inline-block;
    border-color: #c0e5ee;
    border-radius: 5px;
    border-style: solid;
    border-width: 1.2px;
    margin-right: 12px;
    margin-bottom: 12px;
}

.copyProductsModal {
    .lineSeparator {
        border-bottom: 1px solid #e5e5e5;
        margin: 20px 0;
    }

    font.grey {
        color: var(--color-grey-text);
    }

    .packsList {
        div.pack {
            display: inline-block;
            width: 270px;
            font-family: 'standard';
            font-size: 0.95em;
            text-transform: none;
            margin-bottom: 5px;
        }
    }

    .twoCols {
        div {
            display: block;
            margin-bottom: 10px;

            span {
                font-family: 'bold';
                display: inline-block;
                text-align: right;
                margin-right: 10px;
                width: 150px;
            }
        }
    }

    .modalBottom {
        display: grid;
        grid-template-columns: auto 30%;
        column-gap: 2em;
        align-items: center;
    }
}
