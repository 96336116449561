.container {
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    margin: 0 2px 0 2px;

    --chip-background: var(--color-default);
    --chip-text: var(--color-white);

    background-color: var(--chip-background);
    color: var(--chip-text);

    &.primary {
        --chip-background: var(--color-primary);
    }

    &.secondary {
        --chip-background: var(--color-secondary);
        --chip-text: var(--color-black);
    }

    &.positive {
        --chip-background: var(--color-positive);
    }

    &.warning {
        --chip-background: var(--color-warning);
    }

    &.danger {
        --chip-background: var(--color-danger);
    }
}
