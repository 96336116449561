.spinner {
    --internal-spinner-color: var(--spinner-color, var(--color-white, white));

    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid
        color-mix(in srgb, var(--internal-spinner-color) 20%, transparent);
    border-radius: 50%;
    border-top-color: var(--internal-spinner-color);
    animation: spin 1s ease-in-out infinite;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
