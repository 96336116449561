.container {
    --floating-menu-height: 140px;

    margin-bottom: var(--floating-menu-height);
    position: relative;
}

.navigation {
    padding: 0;
    margin: 0;
    float: left;
}
