.saveMenu {
    position: fixed;
    right: 25px;
    bottom: 25px;
    padding: 20px;

    // Spacing * 2, Padding * 2
    height: calc(var(--floating-menu-height) - 25px * 2 - 20px * 2);

    background-color: var(--color-white);
    border-radius: 7px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);

    display: flex;
    gap: 10px;
}

.empty {
    padding: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.createButtonContainer {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;

    & .createButton {
        margin-left: auto;
    }
}
