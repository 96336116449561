.table {
    width: 100%;
}

.menu {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 20px;
}

.actionsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.createButtonContainer {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;

    & .createButton {
        margin-left: auto;
    }
}
