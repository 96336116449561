.selector {
    margin-bottom: 12px;
}


input {
    margin-top: 6px;
}

.translationsContainer {
    display: inline-flex;
    align-items: flex-start;
    gap: 20px;
}

.translationsHeaderContainer {
    display: inline-flex;
    align-items: center;
    gap: 5px;
}
