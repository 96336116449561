.statusLabel {
    font-size: 14px;
    line-height: 0.8em;
    text-transform: capitalize;
    border: 1px solid;
    border-radius: 4px;
    padding: 7px 8px 6px;
    display: inline-block;

    &.active {
        color: var(--color-status-active-text);
        border-color: var(--color-status-active-border);
        background-color: var(--color-status-active-bg);
    }

    &.inactive {
        color: var(--color-status-inactive-text);
        border-color: var(--color-status-inactive-border);
        background-color: var(--color-status-inactive-bg);
    }

    &.default {
        color: var(--color-status-default-text);
        border-color: var(--color-status-default-border);
        background-color: var(--color-status-default-bg);
    }
}
