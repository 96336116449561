.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 22px;
}

.toggleCheckbox {
    display: none;

    &:checked + .toggleSlider {
        background-color: var(--color-primary);
    }

    &:checked + .toggleSlider::before {
        transform: translateX(10px);
    }
}

.toggleSlider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-secondary-dark);
    border-radius: 22px;
    transition: 0.3s;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        left: 3px;
        bottom: 3px;
        background-color: var(--color-white);
        border-radius: 50%;
        transition: 0.3s;
    }
}
